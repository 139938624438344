@import "../node_modules/primeflex/primeflex.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/@flaticon/flaticon-uicons/css/all/all";
@import "./theme.css";
@import "primereact/resources/primereact.min.css";
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --text: #ffffff;
  --background: #fffafa;
  --primary: #6c6a90;
  --secondary: #9191c0;
  --accent: #ff4f00;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", "Heebo", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.Hotspot {
  --min-hotspot-opacity: 0;
}
@keyframes highlightText {
  0%,
  100% {
    color: var(--text-white);
  }
  50% {
    color: var(--primary-color);
  }
}

.highlighted-text {
  animation: highlightText 3s ease-in-out infinite;
  /* You can adjust the animation duration (3s) as needed */
}

@keyframes scrollBullets {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.bullet-scroll-container {
  white-space: nowrap;
  overflow: hidden;
  width: 100%; /* Adjust the width to your layout */
}

.bullet-scroll {
  display: inline-block;
  animation: scrollBullets 250s linear infinite; /* Adjust the animation duration as needed */
}

.bullet-item {
  margin-right: 1rem; /* Adjust the spacing between bullets */
}
/* ===== Scrollbar CSS ===== */
.heroSpan {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border-radius: 10px;
}
.heroSubtitle {
  background-image: linear-gradient(gold, gold);
  background-size: 100% 10px;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  transition: background-size 0.7s, background-position 0.5s ease-in-out;
  color: #fff;
}
.heroSubtitle:hover {
  background-size: 100% 100%;
  background-position: 0% 100%;
  transition: background-position 0.7s, background-size 0.5s ease-in-out;
  color: #262d69;
}
.heroImage {
  filter: grayscale(100%) blur(3px);
}
.heroImage:hover {
  filter: grayscale(100%) blur(0px);
}
.swiper-button-prev::after {
  color: #fff;
  font-weight: 900;
}
.swiper-button-next::after {
  font-weight: 900;
  color: #fff;
}
.blackGradient {
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 1, 8, 0.3) 79%,
    rgba(1, 1, 1, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 1, 8, 0.3) 79%,
    rgba(1, 1, 1, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 1, 8, 0.3) 79%,
    rgba(1, 1, 1, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#010101",GradientType=1);
}
.greyGradient {
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    0deg,
    rgba(2, 0, 36, 0.6) 0%,
    rgba(1, 1, 1, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(2, 0, 36, 0.6) 0%,
    rgba(1, 1, 1, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 0.6) 0%,
    rgba(1, 1, 1, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#010101",GradientType=1);
}

/* Apply styles only to screens with a minimum width of 768 pixels (adjust as needed) */
@media (min-width: 768px) {
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #39384c #fff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    /* background: #ffffff; */
  }

  *::-webkit-scrollbar-thumb {
    background-color: #39384c;
    border-radius: 8px;
    border: 3px solid #ffffff;
  }
}
